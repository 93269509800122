<template>
  <div style="width: 100%; padding: 10px">
    <h1>{{ $t("fitness.header") }}</h1>
    <p style="margin-top: 5px; text-align: center">
      <v-btn @click="dayBack" text>
        <i class="fal fa-chevron-left"></i>
      </v-btn>
      <v-btn text @click="d_date_select = true">
        <i
          style="margin-left: 5px; cursor: pointer"
          class="fad fa-calendar-day"
        ></i>
      </v-btn>
      <v-btn @click="dayForward" :disabled="todayDate === cardDate" text>
        <i class="fal fa-chevron-right"></i>
      </v-btn>
      <br>
      <span style="font-size:23px;">{{ formattedDate }}</span>
    </p>
    <v-layout
      row
      wrap
      style="margin-left: 0; margin-right: 0; margin-top: 20px"
    >
      <info-tile
        :name="$t('fitness.steps')"
        :data="current_fitness_data ? current_fitness_data.steps : null"
        icon="shoe-prints"
      ></info-tile>
      <info-tile
        :name="$t('fitness.calories')"
        :data="current_fitness_data ? current_fitness_data.calories : null"
        icon="flame"
      ></info-tile>
      <info-tile
        :name="$t('fitness.floors')"
        :data="current_fitness_data ? Math.floor(current_fitness_data.floors) : null"
        icon="level-up"
      ></info-tile>
      <info-tile
        :name="$t('fitness.distance')"
        :data="
          current_fitness_data ? current_fitness_data.distance + ' km' : null
        "
        icon="map"
      ></info-tile>
      <info-tile
        :name="$t('fitness.resting_heart_rate')"
        :data="
          current_fitness_data && current_fitness_data.heart_rate
            ? current_fitness_data.heart_rate + ' bpm'
            : null
        "
        icon="heart"
      ></info-tile>
      <info-tile
        :name="$t('fitness.sleep_effective')"
        :data="
          current_fitness_data && current_fitness_data.sleep_duration
            ? getHoursAndMinutesFromMinutes(current_fitness_data.sleep_duration)
            : null
        "
        icon="moon"
      ></info-tile>
      <info-tile
        :name="$t('fitness.sleep_total')"
        :data="
          current_fitness_data && current_fitness_data.sleep_total
            ? getHoursAndMinutesFromMinutes(current_fitness_data.sleep_total)
            : null
        "
        icon="moon"
      ></info-tile>
    </v-layout>
    <v-layout
      row
      wrap
      style="margin: 5px"
      :style="{ marginTop: current_fitness_data ? '20px' : '5px' }"
    >
      <v-flex xs12 sm12 style="padding-top: 10px; text-align: center">
        <p style="margin-right: 10px" v-if="current_fitness_data === undefined">
          {{ $t("fitness.no_data_here") }}
        </p>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="l_import"
              :disabled="l_import"
              color="default"
              v-bind="attrs"
              v-on="on"
              style="margin-bottom: 20px"
            >
              <i class="fad fa-file-import" style="margin-right: 5px"></i>
              {{ $t("fitness.import") }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="startImportFitbit">
              <v-list-item-title>Fitbit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="startImportWithings">
              <v-list-item-title>Withings</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm12 v-if="current_fitness_data">
        <p
          style="width: 100%; text-align: right; margin-top: 10px"
          v-if="current_fitness_data"
        >
          {{
            $t("source") +
            ": " +
            current_fitness_data.imported_from[0].toUpperCase() +
            current_fitness_data.imported_from.slice(1)
          }}
        </p>
      </v-flex>
    </v-layout>

    <v-dialog v-model="d_date_select" max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          $t("today.select_date")
        }}</v-card-title>
        <v-card-text style="text-align: center">
          <v-date-picker
            :allowed-dates="allowedDates"
            :first-day-of-week="1"
            :value="cardDate"
            @change="selectDate"
          ></v-date-picker>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { storage } from "../schmucklicloud";
import { Notifications } from "../notification";
import InfoTile from "../components/fitness/InfoTile.vue";
var numeral = require("numeral");

var start_date = new Date();
start_date.setDate(start_date.getDate() - 1);

export default {
  data() {
    return {
      current_date: start_date,
      current_fitness_data: undefined,
      current_fitness_data_row_id: undefined, // The row id from the schmuckliCloud service

      //Dialogs
      d_date_select: false,

      l_today: false,
      l_import: false, // Is the import session active?

      add_title: "",
      add_text: "",

      withings_auth_attempt: 0,

      today: null,
      mood: 0,
    };
  },
  components: {
    InfoTile,
  },
  watch: {
    cardDate() {
      this.loadData(this.cardDate);
    },
  },
  mounted() {
    this.loadData(this.cardDate);
  },
  computed: {
    cardDate() {
      return (
        this.current_date.getFullYear() +
        "-" +
        (this.current_date.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (this.current_date.getDate() + "").padStart(2, "0")
      );
    },
    formattedDate() {
      var months = [
        this.$t("general.month.january"),
        this.$t("general.month.february"),
        this.$t("general.month.march"),
        this.$t("general.month.april"),
        this.$t("general.month.may"),
        this.$t("general.month.june"),
        this.$t("general.month.july"),
        this.$t("general.month.august"),
        this.$t("general.month.september"),
        this.$t("general.month.october"),
        this.$t("general.month.november"),
        this.$t("general.month.december"),
      ];

      var day_of_week = [
        this.$t("general.day_of_week.sunday"),
        this.$t("general.day_of_week.monday"),
        this.$t("general.day_of_week.tuesday"),
        this.$t("general.day_of_week.wednesday"),
        this.$t("general.day_of_week.thursday"),
        this.$t("general.day_of_week.friday"),
        this.$t("general.day_of_week.saturday"),
      ];

      return (
        day_of_week[this.current_date.getDay()] +
        ", " +
        this.current_date.getDate() +
        ". " +
        months[this.current_date.getMonth()] +
        " " +
        this.current_date.getFullYear()
      );
    },
    todayDate() {
      var today = new Date();
      return (
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 + "").padStart(2, "0") +
        "-" +
        (today.getDate() + "").padStart(2, "0")
      );
    },
  },
  methods: {
    dayBack() {
      this.current_date.setDate(this.current_date.getDate() - 1);
      this.current_date = new Date(this.current_date);
    },
    dayForward() {
      this.current_date.setDate(this.current_date.getDate() + 1);
      this.current_date = new Date(this.current_date);
    },

    selectDate(date) {
      this.current_date = new Date(date);
      this.d_date_select = false;
    },

    // Load the data from the sC Service
    async loadData(date) {
      var response = await storage.get("fitness_daily", [
        {
          column: "date",
          operator: "==",
          value: date,
        },
      ]);

      if (response.isOK) {
        if (response.data) {
          this.current_fitness_data_row_id = response.data[0].id;
          this.current_fitness_data = response.data[0].data;

          // Formatting the data
          this.current_fitness_data.steps = numeral(
            this.current_fitness_data.steps
          )
            .format("0,000")
            .replace(/,/g, "'");
          this.current_fitness_data.calories = numeral(
            this.current_fitness_data.calories
          )
            .format("0,000")
            .replace(/,/g, "'");

          this.current_fitness_data.distance = numeral(
            this.current_fitness_data.distance
          )
            .format("0.00")
            .replace(/,/g, "'");
        } else {
          this.current_fitness_data_row_id = undefined;
          this.current_fitness_data = undefined;
        }
      } else {
        this.current_fitness_data_row_id = undefined;
        this.current_fitness_data = undefined;
      }
    },

    // Start the import from Fitbit Services
    async startImportFitbit() {
      this.l_import = true;
      var token_object = localStorage.getItem("fitbit_auth") || undefined;
      if (token_object === undefined) {
        this.l_import = false;
        this.authenticateFitbit();
      } else {
        var access_token = JSON.parse(token_object).access_token;

        // Clean data / Remove the data, which is already there for the active date
        if (this.current_fitness_data_row_id !== undefined) {
          await storage.delete(
            "fitness_daily",
            this.current_fitness_data_row_id
          );
        }

        // Steps / calories / floors / distances data
        try {
          var response_steps = await axios.get(
            "https://api.fitbit.com/1/user/-/activities/date/" +
              this.cardDate +
              ".json",
            {
              headers: { Authorization: "Bearer " + access_token },
            }
          );
          var data_steps = response_steps.data.summary;

          var sDistance = 0;
          data_steps.distances.forEach(
            function (distance) {
              if (distance.activity === "total") {
                sDistance = distance.distance;
              }
            }.bind(this)
          );

          var sC_response_steps = await storage.insert("fitness_daily", {
            date: this.cardDate,
            steps: data_steps.steps,
            calories: data_steps.caloriesOut,
            distance: sDistance,
            floors: data_steps.floors,
            imported_from: "fitbit",
          });

          if (!sC_response_steps.isOK) {
            Notifications.show(
              "There was a problem while importing the steps, etc."
            );
          }

          var rowset_id = sC_response_steps.data.id;

          // Resting heart rate data
          var response_heart_rate = await axios.get(
            "https://api.fitbit.com/1/user/-/activities/heart/date/" +
              this.cardDate +
              "/1d.json",
            {
              headers: { Authorization: "Bearer " + access_token },
            }
          );
          var heart_rate =
            response_heart_rate.data["activities-heart"][0].value
              .restingHeartRate;

          var sC_response_heart_rate = await storage.update(
            "fitness_daily",
            rowset_id,
            {
              heart_rate: heart_rate,
            }
          );
          if (!sC_response_heart_rate.isOK) {
            Notifications.show(
              "There was a problem while importing the heart rate."
            );
          }

          // Sleep data
          var response_sleep = await axios.get(
            "https://api.fitbit.com/1.2/user/-/sleep/date/" +
              this.cardDate +
              ".json",
            {
              headers: { Authorization: "Bearer " + access_token },
            }
          );

          var data_sleep = response_sleep.data.summary;
          var sC_response_sleep = await storage.update(
            "fitness_daily",
            rowset_id,
            {
              sleep_duration: data_sleep.totalMinutesAsleep,
              sleep_total: data_sleep.totalTimeInBed,
            }
          );
          if (!sC_response_sleep.isOK) {
            Notifications.show(
              "There was a problem while importing the sleep data."
            );
          }

          Notifications.show(
            "The data for " +
              this.formattedDate +
              " has been imported from Fitbit."
          );
          this.l_import = false;
          this.loadData(this.cardDate);
        } catch (ex) {
          this.l_import = false;
          this.authenticateFitbit(); // Reauthenticate
        }
      }
    },
    authenticateFitbit() {
      var fitbit_window = window.open(
        "https://www.fitbit.com/oauth2/authorize?client_id=22BZSM&redirect_uri=https://tracky.schmuckli.dev/fitness/import_fitbit.html&response_type=code&scope=activity heartrate sleep",
        "fitbit-sign-in",
        "height=700,width=550"
      );

      fitbit_window.onbeforeunload = function () {
        this.startImportFitbit();
      }.bind(this);
    },

    async startImportWithings() {
      this.l_import = true;
      var token_object = localStorage.getItem("withings_auth") || undefined;
      if (token_object === undefined) {
        this.authenticateWithings();
      } else {
        var access_token = JSON.parse(token_object).body.access_token;

        try {
          // Clean data / Remove the data, which is already there for the active date
          if (this.current_fitness_data_row_id !== undefined) {
            await storage.delete(
              "fitness_daily",
              this.current_fitness_data_row_id
            );
          }

          // Steps, Elevation, Distance, Calories, HR Average
          var body = new FormData();
          body.append("action", "getactivity");
          body.append(
            "data_fields",
            "steps,elevation,totalcalories,distance,hr_average"
          );
          body.append("category", 2);
          body.append("startdateymd", this.cardDate);
          body.append("enddateymd", this.cardDate);

          var response_metrics = await axios({
            url: "https://wbsapi.withings.net/v2/measure",
            method: "post",
            data: body,
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "multipart/form-data",
            },
          });
          var data_steps = response_metrics.data.body.activities[0];

          var sC_response_steps = await storage.insert("fitness_daily", {
            date: this.cardDate,
            steps: data_steps.steps,
            calories: Math.floor(data_steps.totalcalories),
            distance: data_steps.distance / 1000,
            floors: data_steps.elevation,
            heart_rate: data_steps.hr_average,
            imported_from: "withings",
          });

          if (!sC_response_steps.isOK) {
            Notifications.show(
              "There was a problem while importing the basic data."
            );
          }

          var rowset_id = sC_response_steps.data.id;

          // Sleep data
          var sleep_body = new FormData();
          sleep_body.append("action", "getsummary");
          sleep_body.append(
            "data_fields",
            "durationtosleep,durationtowakeup,lightsleepduration,remsleepduration,deepsleepduration,wakeupduration"
          );
          sleep_body.append("startdateymd", this.cardDate);
          sleep_body.append("enddateymd", this.cardDate);

          var response_sleep = await axios({
            url: "https://wbsapi.withings.net/v2/sleep",
            method: "post",
            data: sleep_body,
            headers: {
              Authorization: "Bearer " + access_token,
              "Content-Type": "multipart/form-data",
            },
          });

          var data_sleep = response_sleep.data.body.series[0].data;

          console.log(response_sleep.data);

          var sleep_duration = 0,
            sleep_total = 0;
          sleep_duration += data_sleep.deepsleepduration;
          if (data_sleep.lightsleepduration) {
            sleep_duration += data_sleep.lightsleepduration;
          }
          if (data_sleep.remsleepduration) {
            sleep_duration += data_sleep.remsleepduration;
          }

          sleep_total += sleep_duration;
          sleep_total += data_sleep.durationtosleep;
          sleep_total += data_sleep.durationtowakeup;
          if (data_sleep.wakeupduration) {
            sleep_total += data_sleep.wakeupduration;
          }

          console.log(sleep_total, sleep_duration);

          var sC_response_sleep = await storage.update(
            "fitness_daily",
            rowset_id,
            {
              sleep_duration: sleep_duration / 60, // To minutes
              sleep_total: sleep_total / 60,
            }
          );

          if (!sC_response_sleep.isOK) {
            Notifications.show(
              "There was a problem while importing the sleep data."
            );
          }

          Notifications.show(
            "The data for " +
              this.formattedDate +
              " has been imported from Withings."
          );
          this.l_import = false;
          this.loadData(this.cardDate);
        } catch (e) {
          this.l_import = false;
          this.authenticateWithings();
        }
      }
    },
    async authenticateWithings() {
      var withings_auth = localStorage.getItem("withings_auth") || undefined;
      if (withings_auth !== undefined && this.withings_auth_attempt === 0) {
        this.withings_auth_attempt++;

        if (!JSON.parse(withings_auth).body.refresh_token) {
          this.authenticateWithings();
          return;
        }

        var response = await axios.get(
          "https://schmuckli-dev-functions.herokuapp.com/tracky/fitness_withings?" +
            new URLSearchParams({
              refresh_token: JSON.parse(withings_auth).body.refresh_token,
              function: "refresh_token",
            }).toString()
        );

        if (response.status === 200) {
          if (response.data.status !== 0) {
            this.authenticateWithings();
            return;
          } else {
            localStorage.setItem(
              "withings_auth",
              JSON.stringify(response.data)
            );
            this.startImportWithings();
          }
        }
      } else {
        var withings_window = window.open(
          "https://account.withings.com/oauth2_user/authorize2?client_id=87231d61e5ea317f7d0fb6eb1328210042bbf989a2c1084904bb0f9a3406b491&redirect_uri=https://tracky.schmuckli.dev/fitness/import_withings.html&response_type=code&scope=user.activity,user.metrics&state=" +
            new Date().getTime(),
          "withings-sign-in",
          "height=700,width=550"
        );

        withings_window.onbeforeunload = function () {
          this.startImportWithings();
        }.bind(this);
      }
    },

    // Formatters
    allowedDates(val) {
      var date = new Date(val);
      return new Date() - date > 0;
    },
    getHoursAndMinutesFromMinutes(input_minutes) {
      var minutes = parseInt(input_minutes);
      var hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return hours + " hrs " + minutes + " mins";
    },
  },
};
</script>

<style>
.mx-auto {
  margin-bottom: 30px;
}
</style>

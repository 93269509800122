var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","padding":"10px"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("fitness.header")))]),_c('p',{staticStyle:{"margin-top":"5px","text-align":"center"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.dayBack}},[_c('i',{staticClass:"fal fa-chevron-left"})]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.d_date_select = true}}},[_c('i',{staticClass:"fad fa-calendar-day",staticStyle:{"margin-left":"5px","cursor":"pointer"}})]),_c('v-btn',{attrs:{"disabled":_vm.todayDate === _vm.cardDate,"text":""},on:{"click":_vm.dayForward}},[_c('i',{staticClass:"fal fa-chevron-right"})]),_c('br'),_c('span',{staticStyle:{"font-size":"23px"}},[_vm._v(_vm._s(_vm.formattedDate))])],1),_c('v-layout',{staticStyle:{"margin-left":"0","margin-right":"0","margin-top":"20px"},attrs:{"row":"","wrap":""}},[_c('info-tile',{attrs:{"name":_vm.$t('fitness.steps'),"data":_vm.current_fitness_data ? _vm.current_fitness_data.steps : null,"icon":"shoe-prints"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.calories'),"data":_vm.current_fitness_data ? _vm.current_fitness_data.calories : null,"icon":"flame"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.floors'),"data":_vm.current_fitness_data ? Math.floor(_vm.current_fitness_data.floors) : null,"icon":"level-up"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.distance'),"data":_vm.current_fitness_data ? _vm.current_fitness_data.distance + ' km' : null,"icon":"map"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.resting_heart_rate'),"data":_vm.current_fitness_data && _vm.current_fitness_data.heart_rate
          ? _vm.current_fitness_data.heart_rate + ' bpm'
          : null,"icon":"heart"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.sleep_effective'),"data":_vm.current_fitness_data && _vm.current_fitness_data.sleep_duration
          ? _vm.getHoursAndMinutesFromMinutes(_vm.current_fitness_data.sleep_duration)
          : null,"icon":"moon"}}),_c('info-tile',{attrs:{"name":_vm.$t('fitness.sleep_total'),"data":_vm.current_fitness_data && _vm.current_fitness_data.sleep_total
          ? _vm.getHoursAndMinutesFromMinutes(_vm.current_fitness_data.sleep_total)
          : null,"icon":"moon"}})],1),_c('v-layout',{staticStyle:{"margin":"5px"},style:({ marginTop: _vm.current_fitness_data ? '20px' : '5px' }),attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"padding-top":"10px","text-align":"center"},attrs:{"xs12":"","sm12":""}},[(_vm.current_fitness_data === undefined)?_c('p',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("fitness.no_data_here"))+" ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-bottom":"20px"},attrs:{"loading":_vm.l_import,"disabled":_vm.l_import,"color":"default"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fad fa-file-import",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t("fitness.import"))+" ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.startImportFitbit}},[_c('v-list-item-title',[_vm._v("Fitbit")])],1),_c('v-list-item',{on:{"click":_vm.startImportWithings}},[_c('v-list-item-title',[_vm._v("Withings")])],1)],1)],1)],1),(_vm.current_fitness_data)?_c('v-flex',{attrs:{"xs12":"","sm12":""}},[(_vm.current_fitness_data)?_c('p',{staticStyle:{"width":"100%","text-align":"right","margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("source") + ": " + _vm.current_fitness_data.imported_from[0].toUpperCase() + _vm.current_fitness_data.imported_from.slice(1))+" ")]):_vm._e()]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.d_date_select),callback:function ($$v) {_vm.d_date_select=$$v},expression:"d_date_select"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("today.select_date")))]),_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"first-day-of-week":1,"value":_vm.cardDate},on:{"change":_vm.selectDate}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }